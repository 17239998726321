import { SxProps } from "@mui/system/styleFunctionSx/styleFunctionSx";
import { Box, Grid, Switch, TextField, Typography } from "@mui/material";
import { isArray } from "lodash";
import React, { ChangeEvent } from "react";
import { createTitle } from "./ShowConfigEl";

interface iProps {
  title: string,
  value: string | string[] | boolean | number | null,
  sx?: SxProps,
  onUpdate: Function,
}

export const UpdateConfigRow = ({title, value, sx, onUpdate}: iProps) => {
  if (typeof value === "boolean") {
    return (
      <Grid container spacing={1} alignItems={"center"} justifyContent={"space-between"} sx={sx}>
        <Grid item sx={{flex: 0}}>
          <Typography sx={{fontSize: 13, whiteSpace: 'nowrap'}} color="text.secondary">{title}</Typography>
        </Grid>
        <Grid item sx={{flex: 0}}>
          <Switch checked={value} onChange={(e, checked) => onUpdate(checked)}/>
        </Grid>
      </Grid>
    )
  } else {
    return (
      <Box sx={{
        mb: 1,
      }}>
        <TextField
          defaultValue={value}
          variant={"standard"}
          label={createTitle(title)}
          maxRows={isArray(value) ? 3 : 1}
          sx={{
            width: `100%`,
          }}
          type={typeof value === "number" ? "number" : `text`}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const val = e.target.value;
            const _st = () => {
              if (isArray(value))
                return val.split(',').filter((v: string) => !!v.trim()).map((v: string) => v.trim());
              else
                return val;
            }
            onUpdate(_st());
          }}
        />
      </Box>
    )
  }
}