import { CircularProgress, CssBaseline, ThemeProvider } from "@mui/material";
import axios from "axios";
import { DateTime } from 'luxon';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import CacheBuster from 'react-cache-buster';
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { getLogout } from "./dataStore/features/authSlice";
import { enqueueSnackbar } from "./dataStore/features/notifierSlice";
import { store } from "./dataStore/store";
import { AppInit } from "./screens/Initial";
import './styles/global.css';
import { theme } from "./utilities/theme";
import { Endpoints } from "./dataStore/Endpoints";

// Axios Globals
axios.defaults.baseURL = Endpoints.base;
axios.defaults.timeout = 2000;

const token: string | null = localStorage.getItem('token')
if (!!token) {
	axios.defaults.headers.common[`Authorization`] = `Bearer ${token}`;
}
axios.defaults.headers.common[`x-device-timezone`] = DateTime.now().zoneName;
axios.defaults.headers.common[`x-device-type`] = `web`
axios.interceptors.response.use(async (response) => {
	try {
		if ([401, 403].includes(response.data?.error?.code)) {
			store.dispatch(getLogout());
			store.dispatch(enqueueSnackbar({
				type: "error",
				message: response.data.error?.message || `Something went wrong!`
			}))
			return null;
		}
		return response;
	} catch (e) {
		return Promise.reject(e);
	}
})

const App = () => {
	return (
		<CacheBuster
			currentVersion={require('../package.json').version}
			isEnabled={process.env.NODE_ENV !== "development"} //If false, the library is disabled.
			isVerboseMode={process.env.NODE_ENV === 'production'} //If true, the library writes verbose logs to console.
			loadingComponent={<CircularProgress/>} //If not pass, nothing appears at the time of new version check.
			onCacheClear={() => {
				console.log(`cache cleared`);
			}}
		>
			<Provider store={store}>
				<ThemeProvider theme={theme}>
					<CssBaseline enableColorScheme/>
					<SnackbarProvider maxSnack={10}>
						<BrowserRouter>
							<AppInit/>
						</BrowserRouter>
					</SnackbarProvider>
				</ThemeProvider>
			</Provider>
		</CacheBuster>
	);
}

export default App;
