import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppModel, AppResponseModel } from "../models/AppModel";

const initialState: AppModel = {
  loading: false,
  data: {
    error: null,
    result: [],
  },
}

export const adminSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAppLoaderON: (state: AppModel) => {
      state.loading = true;
    },
    setAppLoaderOFF: (state: AppModel) => {
      state.loading = false;
    },
    setAppData: (state: AppModel, action: PayloadAction<AppResponseModel>) => {
      state.data = action.payload;
    },
  },
});

export const {
  setAppData,
  setAppLoaderOFF,
  setAppLoaderON,
} = adminSlice.actions

export default adminSlice.reducer