import { SyntheticEvent } from "react";
import { store } from "../store";
import { getLogged, getLogout, setLoaderOFF, setLoaderON } from "../features/authSlice";
import { LoginModel, } from "../models/LoginModel";
import axios from "axios";
import { enqueueSnackbar } from "../features/notifierSlice";
import { Endpoints } from "../Endpoints";

export class AuthAPI {
  static getLoggedIn = async (e: SyntheticEvent): Promise<boolean> => {
    e.preventDefault();
    store.dispatch(setLoaderON());
    try {
      const target = e.target as typeof e.target & {
        email: { value: string };
        password: { value: string };
      };
      const email = target.email.value;
      const password = target.password.value;

      const {error, result}: LoginModel = (await axios.post(Endpoints.login, {
        email,
        password
      })).data;

      if (!error && !!result) {
        await AuthAPI.onLoggedIn(result.token);
        store.dispatch(enqueueSnackbar({type: "success", message: 'Success'}))
        return true;
      } else {
        let msg = error?.message || `Something went wrong!`;
        if (!!error?.errors && error?.errors.length) {
          msg = error.errors[0].message;
        }
        store.dispatch(enqueueSnackbar({type: "error", message: msg}))
        return false;
      }
    } catch (e: any) {
      store.dispatch(getLogout())
      store.dispatch(enqueueSnackbar({type: "error", message: e?.message || `Something went wrong!`}))
      return false;
    } finally {
      store.dispatch(setLoaderOFF());
    }
  }

  static getRefreshed = async () => {
    store.dispatch(setLoaderON());
    try {
      const {error, result}: LoginModel = (await axios.post(Endpoints.refresh)).data;

      if (!error && !!result?.token) {
        await AuthAPI.onLoggedIn(result.token);
      } else {
        store.dispatch(getLogout())
      }
    } catch (e: any) {
      store.dispatch(getLogout())
    } finally {
      store.dispatch(setLoaderOFF());
    }
  }

  static onLoggedIn = async (token: string) => {
    localStorage.setItem('token', token);
    store.dispatch(getLogged(token));
  }
}