import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, ButtonGroup, Container, Grid, Typography } from "@mui/material";
import { isArray, isEmpty } from "lodash";
import { useState } from "react";
import { UpdateConfigRow } from "./UpdateConfigRow";
import { createTitle } from "./ShowConfigEl";
import LoadingButton from '@mui/lab/LoadingButton';
import AddIcon from '@mui/icons-material/Add';
import { AddNewItem } from './addNewItem';

interface rProps {
  title: string,
  value: any | { [key: string]: any; },
  keys?: string[],
  onUpdate: (keys: string[], val: string | string[] | boolean) => void,
}

export const UpdateConfigEl = ({title, value, keys, onUpdate}: rProps) => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const [open, setOpen] = useState<boolean>(false);

  if (typeof value === 'object' && !isArray(value) && value !== null) {
    if (isEmpty(value)) {
      return <>
        <Accordion expanded={expanded === title} onChange={() => {
          if (!open) {
            setExpanded(expanded === title ? false : title)
          }
        }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls={`${title}-content`}
            id={`${title}-header`}
            sx={{
              '&.Mui-expanded': {
                minHeight: 0,
                '.css-o4b71y-MuiAccordionSummary-content.Mui-expanded': {
                  m: `12px 0 0`,
                }
              }
            }}
          >
            <Typography>{createTitle(title)}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {!isEmpty(value) && Object.keys(value).map((a: any) => <UpdateConfigEl {...{
              title: a,
              value: value[a],
              keys: [...(keys || []), a],
              key: `access-value-key-${a}`,
              onUpdate,
            }} />)}
          </AccordionDetails>
          <AddNewItem setOpen={setOpen} open={open} keys={keys} onUpdate={onUpdate}></AddNewItem>
          <Container
            sx={{height: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex', flex: 'auto'}}>
            <Grid item>
              <ButtonGroup variant="text" aria-label="app list" size={"large"}>
                <LoadingButton
                  onClick={() => setOpen(!open)}
                  startIcon={<AddIcon/>}
                  loadingPosition="start">
                  Add new Field
                </LoadingButton>
              </ButtonGroup>
            </Grid>
          </Container>
        </Accordion>

      </>
    } else {
      if (!title) {
        return (
          <>
            {!isEmpty(value) && Object.keys(value).map((a: any) => <UpdateConfigEl {...{
              title: a,
              value: value[a],
              keys: [...(keys || []), a],
              key: `access-value-key-${a}`,
              onUpdate,
            }} />)}
          </>
        )
      } else {
        return (
          <Accordion expanded={expanded === title} onChange={() => {
            if (!open) {
              setExpanded(expanded === title ? false : title)
            }
          }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon/>}
              aria-controls={`${title}-content`}
              id={`${title}-header`}
              sx={{
                '&.Mui-expanded': {
                  minHeight: 0,
                  '.css-o4b71y-MuiAccordionSummary-content.Mui-expanded': {
                    m: `12px 0 0`,
                  }
                }
              }}
            >
              <AddNewItem setOpen={setOpen} open={open} keys={keys} onUpdate={onUpdate}></AddNewItem>
              <Typography>{createTitle(title)}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {!isEmpty(value) && Object.keys(value).map((a: any) => <UpdateConfigEl {...{
                title: a,
                value: value[a],
                keys: [...(keys || []), a],
                key: `access-value-key-${a}`,
                onUpdate,
              }} />)}
            </AccordionDetails>
            <Container
              sx={{height: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex', flex: 'auto'}}>
              <Grid item>
                <ButtonGroup variant="text" aria-label="app list" size={"large"}>
                  <LoadingButton
                    onClick={() => setOpen(!open)}
                    startIcon={<AddIcon/>}
                    loadingPosition="start">
                    Add new Field
                  </LoadingButton>
                </ButtonGroup>
              </Grid>
            </Container>
          </Accordion>
        )
      }
    }
  } else {
    return (
      <UpdateConfigRow
        title={createTitle(title)}
        value={value}
        onUpdate={(val: string | string[] | boolean) => onUpdate(keys!, val)}
      />
    )
  }
}