import React from "react";
import { Container, Grid } from "@mui/material";
import { useAppSelector } from "../../dataStore/hooks";
import { RootState, store } from "../../dataStore/store";
import { ConfigModel } from "../../dataStore/models/ConfigModel";
import { IAdminFlavor, IMobileFlavor } from "hrms_types";
import { useParams } from "react-router-dom";
import { setData, setLoaderOFF, setLoaderON } from "../../dataStore/features/configSlice";
import axios from "axios";
import { Endpoints } from "../../dataStore/Endpoints";
import { ConfigApi } from "../../dataStore/apis/configApi";
import { enqueueSnackbar } from "../../dataStore/features/notifierSlice";
import { ConfigCard } from "./ConfigCard";

export const ConfigItems = () => {
  const {type} = useParams();
  const {data}: ConfigModel = useAppSelector((state: RootState) => state.configs);
  const {result} = data;

  const onDelete = async (id: string) => {
    try {
      store.dispatch(setLoaderON());
      const res = (await axios.delete(Endpoints.deleteApps(type!, id))).data;
      if (res.error == null) {
        await ConfigApi.getApps(type || ``);
        store.dispatch(setData({...data, result: result.filter((r: any) => r.id !== id)}));
      } else {
        store.dispatch(enqueueSnackbar({type: "error", message: `Something went wrong!`}));
      }
    } catch (e: any) {
      store.dispatch(enqueueSnackbar({type: "error", message: e?.message || `Something went wrong!`}));
    } finally {
      store.dispatch(setLoaderOFF());
    }
  }

  return (
    <Container>
      <Grid container spacing={2} justifyContent={'center'}>
        {(data.result || []).map((item: IAdminFlavor | IMobileFlavor) => (
          <ConfigCard {...{item: item, onDelete, key: `config-item-${item.id}`}}/>
        ))}
      </Grid>
    </Container>
  );
}
