import React, { useEffect } from 'react';
import { RootState } from "../../dataStore/store";
import { useAppSelector } from "../../dataStore/hooks";
import { FullPageLoader2 } from "./fullPageLoader";
import { useNavigate } from "react-router-dom";
import { AuthAPI } from "../../dataStore/apis/authAPI";

interface IAuthRouteProps {
  children: JSX.Element,
}

const AuthRoute = ({children}: IAuthRouteProps) => {
  const localToken = localStorage.getItem('token');
  const {loading, token} = useAppSelector((st: RootState) => st.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && !token) {
      if (!localToken)
        navigate('/login');
      else
        (async () => await AuthAPI.getRefreshed())();
    }
  }, [loading, token, navigate, localToken])

  return (!!localToken && !token) || loading ? <FullPageLoader2/> : children;
};

export default AuthRoute;
