export interface ValidationErrorModel {
  param: string;
  message: string;
}

export interface LoginModel {
  error: ErrorModel | null,
  result?: result;
}

export interface result {
  token: string,
}

export interface CountryModel {
  code: string;
  name: string;
  id: string;
}

export interface IAdminDataModel {
  email: string;
  name: string;
  login_count: number;
  role: string;
  apiKey?: any;
  country: CountryModel;
  updatedAt: string;
  id: string;
  department?: string;
}

export class AdminDataModel {
  email: string;
  name: string;
  loginCount: number;
  role: string;
  apiKey?: any;
  country: CountryModel;
  id: string;
  department?: string;

  constructor({ name, email, login_count, country, id, role, apiKey, department }: IAdminDataModel) {
    this.id = id;
    this.name = name;
    this.email = email;
    this.role = role;
    this.loginCount = login_count;
    this.apiKey = apiKey;
    this.country = country;
    this.department = department;
  }

  public get adminRole(): string {
    switch (this.role) {
      case 'root':
        return 'Super';
      case 'admin':
        return 'Admin';
      default:
        return 'Moderator';
    }
  }
}

export interface AdminDataWrapperModel {
  data: IAdminDataModel;
  iat: number;
  exp: number;
}

export interface LoginResponseModel {
  token: string | null;
  admin: IAdminDataModel | null;
  loading: boolean;
}

export interface UpdateProfileResponseModel {
  error: ErrorModel | null,
  message?: string,
  refreshedToken?: string,
}

export interface ErrorModel {
  code: number,
  errors?: ValidationErrorModel[],
  message: string
}