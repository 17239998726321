import React, {Fragment} from 'react';
import {Container, Grid,} from "@mui/material";
import {useAppSelector} from "../../dataStore/hooks";
import {isLoggedIn} from "../../dataStore/features/authSlice";
import ProfileMenu from "./profileMenu";
import {Link} from "react-router-dom";

const AppHeader = () => {
  const loggedIn = useAppSelector(isLoggedIn);

  return (
    <Container component={"header"} sx={{py: 2}}>
      <Grid container spacing={2} justifyContent={"space-between"} alignItems={`center`}>
        <Grid item>
          <Link to={'/'} style={{display: "block", lineHeight: 1}}><img src="/images/logo.svg" alt="flyhub"/></Link>
        </Grid>
        <Grid item>
          {loggedIn ? <ProfileMenu/> : <Fragment/>}
        </Grid>
      </Grid>
    </Container>
  );
};

export default AppHeader;
