import React from 'react';
import { Breadcrumbs, Container, Grid } from "@mui/material";
import {Link} from 'react-router-dom';
import { grey } from "@mui/material/colors";
import appPack from "../../../package.json";

const AppFooter = () => {
  return (
    <Container component={`footer`} sx={{
      mt: `auto`,
      backgroundColor: "white",
      py: 2,
    }}>
      <Grid container justifyContent={`space-between`}>
        <Grid item>
          App version: {appPack.version}
        </Grid>
        <Grid item>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={"|"}
            sx={{
              display: "flex",
              justifyContent: "center",
              '.MuiBreadcrumbs-li > a': {
                color: grey[800],
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline',
                }
              }
            }}
          >
            <Link to="/">Home</Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </Breadcrumbs>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AppFooter;
