import { createTheme } from "@mui/material";

export const theme = createTheme({
  // palette: {
  //   mode: "dark"
  // }
});

export class AppColors {
  static mainColor: String = "#2196f3";
}