import { Box } from "@mui/material";
import React from "react";
import { Route, Routes } from "react-router-dom";
import AppFooter from "../components/global/footer";
import AppHeader from "../components/global/header";
import useNotifier from "../utilities/useNotifier";
import AppErrorScreen from "./error";
import { HomeScreen } from "./home";
import PrivacyPolicyScreen from "./privacyPolicy";
import { AppsScreen } from "./apps";
import { LoginScreen } from "./login";

export const AppInit = () => {
  useNotifier();

  return (
    <>
      <AppHeader/>
      <Box component={`main`} sx={{
        flex: `auto`,
        width: `100%`,
        display: `flex`,
        flexDirection: "column",
        backgroundColor: `rgb(231, 235, 240)`,
        py: 5,
      }}>
        <Routes>
          <Route path={`/login`} element={<LoginScreen/>}/>
          <Route path={`/`} element={<HomeScreen/>}/>
          <Route path={`/:type-apps`} element={<AppsScreen/>}/>

          {/* Static Pages */}
          <Route path="/privacy-policy" element={<PrivacyPolicyScreen/>}/>
          <Route path="*" element={<AppErrorScreen/>}/>
        </Routes>
      </Box>
      <AppFooter/>
    </>
  )
}