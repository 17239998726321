import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { isArray, isEmpty } from "lodash";
import React, { useState } from "react";
import { ShowConfigRow } from "./ShowConfigRow";

export const createTitle = (t: string) => t.split('_').join(' ')[0].toUpperCase() + t.split('_').join(' ').substring(1);

interface rProps {
  title: string,
  value: any | { [key: string]: any; },
}

export const ShowConfigEl = ({title, value}: rProps) => {
  const [expanded, setExpanded] = useState<string | false>(false);

  if (typeof value === 'object' && !isArray(value) && value !== null) {
    if (isEmpty(value)) {
      return <></>
    } else {
      if (!title) {
        return (
          <>
            {!isEmpty(value) && Object.keys(value).map((a: any) => <ShowConfigEl {...{
              title: a,
              value: value[a],
              key: `access-value-key-${a}`
            }}/>)}
          </>
        )
      } else {
        return (
          <Accordion expanded={expanded === title} onChange={() => setExpanded(expanded === title ? false : title)}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon/>}
              aria-controls={`${title}-content`}
              id={`${title}-header`}
              sx={{
                '&.Mui-expanded': {
                  minHeight: 0,
                  '.css-o4b71y-MuiAccordionSummary-content.Mui-expanded': {
                    m: `12px 0 0`,
                  }
                }
              }}
            >
              <Typography>{createTitle(title)}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {!isEmpty(value) && Object.keys(value).map((a: any) => <ShowConfigEl {...{
                title: a,
                value: value[a],
                key: `access-value-key-${a}`
              }}/>)}
            </AccordionDetails>
          </Accordion>
        )
      }
    }
  } else {
    return <ShowConfigRow sx={{my: 0.5}} title={createTitle(title)} value={value}/>
  }
}