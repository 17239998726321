import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NotificationDataModel, NotificationModel } from "../models/NotificationModel";

const initialState: NotificationDataModel = {
  notifications: [],
}

export const notifierSlice = createSlice({
  name: 'notifiers',
  initialState,
  reducers: {
    enqueueSnackbar: (state: NotificationDataModel, action: PayloadAction<NotificationModel>) => {
      state.notifications = [
        ...state.notifications,
        action.payload,
      ]
    },
    dismissAllSnackbar: (state: NotificationDataModel) => {
      state.notifications = [];
    },
    removeSnackbar: (state: NotificationDataModel, action: PayloadAction<string>) => {
      state.notifications = state.notifications.filter((notification: NotificationModel) => notification.message !== action.payload);
    },
  },
});

export const {
  enqueueSnackbar,
  dismissAllSnackbar,
  removeSnackbar,
} = notifierSlice.actions

export default notifierSlice.reducer