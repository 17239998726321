import { ButtonGroup, Container, Grid, } from '@mui/material';
import AuthRoute from "../components/global/authRoute";
import { FullPageLoader } from '../components/global/fullPageLoader';
import { useAppSelector } from '../dataStore/hooks';
import { AppModel, AppResponseModel } from '../dataStore/models/AppModel';
import AppErrorScreen from './error';
import { useEffect, useState } from "react";
import { ConfigApi } from "../dataStore/apis/configApi";
import { isEmpty } from "lodash";
import { useParams } from "react-router-dom";
import { ConfigItems } from "../components/config/ConfigItems";
import { AppScreenHeader } from "../components/app/appScreenHeader";
import { ConfigModel } from '../dataStore/models/ConfigModel';
import LoadingButton from '@mui/lab/LoadingButton';
import { AddConfigForm } from '../components/config/addConfig';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import { Endpoints } from '../dataStore/Endpoints';
import { store } from '../dataStore/store';
import { enqueueSnackbar } from '../dataStore/features/notifierSlice';
const AppsScreenInnerWrap = () => {
  const { type } = useParams()
  return ['mobile', 'admin'].includes(type || ``) ? <AppsScreenInner /> : <AppErrorScreen />
}

const AppsScreenInner = () => {
  const { type } = useParams()
  const [open, setOpen] = useState<boolean>(false);
  const [selectedAPP, setSelectedAPP] = useState<string | null>(null);
  const [localLoading, setLocalLoading] = useState<boolean>(true);
  const { loading, data }: AppModel = useAppSelector(state => state.app);
  const config: ConfigModel = useAppSelector(state => state.configs);
  const { error, result }: AppResponseModel = data;

  useEffect(() => {
    (async () => await ConfigApi.getApps(type || ``))()
  }, [type]);

  useEffect(() => {
    if (!selectedAPP && !isEmpty((result))) {
      setSelectedAPP(result[0]);
    }
  }, [selectedAPP, result]);

  useEffect(() => {
    if (!!selectedAPP) {
      (async () => await ConfigApi.getConfigs(type!, selectedAPP))();
      setLocalLoading(false)
    }
  }, [type, selectedAPP]);

  const getReload = async () => {
    if (!!type)
      await ConfigApi.getApps(type);
    if (!!selectedAPP)
      await ConfigApi.getConfigs(type!, selectedAPP);
  }
  const submitNewApp = async (payload: any) => {
    setOpen(false);
    try {
      setLocalLoading(true);
      const res = (await axios.post(Endpoints.addApps(type!), payload)).data;
      if (res.error == null) {
        await getReload()
      } else {
        store.dispatch(enqueueSnackbar({ type: "error", message: `Something went wrong!` }))
      }
    } catch (e: any) {
      store.dispatch(enqueueSnackbar({ type: "error", message: e?.message || `Something went wrong!` }));
    } finally {
      setLocalLoading(false);
    }
  }
  if (error) {
    return <AppErrorScreen message={error.message} code={error.code || 500} />
  } else if (result.length === 0) {
    return (<Container sx={{ height: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex', flex: 'auto' }}>
      <Grid item>
        <ButtonGroup variant="text" aria-label="app list" size={"large"}>
          <LoadingButton
            loading={loading}
            onClick={() => setOpen(true)}
            startIcon={<AddIcon />}
            loadingPosition="start"
          >
            Add new {type} APP
          </LoadingButton>
        </ButtonGroup>
      </Grid>
      <AddConfigForm {...{
        open, onClose: () => setOpen(false), onSubmit: async (payload) => submitNewApp(payload)
      }} />
    </Container>);
  }

  else if (localLoading || loading) {
    return <FullPageLoader />
  } else {
    return (
      <Container>
        <AppScreenHeader {...{ result, selectedAPP: selectedAPP || ``, setSelectedAPP }} />
        {config.loading ? <FullPageLoader /> : <ConfigItems />}
      </Container>
    );
  }
};

export const AppsScreen = () => <AuthRoute><AppsScreenInnerWrap /></AuthRoute>


