import LoadingButton from "@mui/lab/LoadingButton";
import { Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import axios from "axios";
import { useState } from 'react'
import { useParams } from "react-router-dom";
import { enqueueSnackbar } from "../../dataStore/features/notifierSlice";
import { useAppDispatch } from "../../dataStore/hooks";
import { IAdminFlavor, IMobileFlavor } from "hrms_types";
import { pick } from "lodash";
import { UpdateConfigEl } from "./UpdateConfigEl";
import { ConfigApi } from "../../dataStore/apis/configApi";
import { updateState } from "./addConfig";

interface iProps {
  item: IMobileFlavor | IAdminFlavor,
  open: boolean,
  onClose: () => void,
}

export const FlavorUpdateForm = ({item, open, onClose}: iProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const {type} = useParams();
  const dispatch = useAppDispatch();
  const [body, setBody] = useState<{ [key: string]: any }>(pick(item, ['essential', 'access']) as { [key: string]: any });

  const handleSubmit = async () => {
    try {
      setLoading(true)
      const {error, result}: any = (await axios.patch(type + `/` + item.id, body)).data;
      if (!!error) {
        dispatch(enqueueSnackbar({type: 'error', message: error?.message || `Something went wrong!`}))
      } else {
        dispatch(enqueueSnackbar({type: 'success', message: result || 'Success!'}))
        await ConfigApi.getConfigs(type!, item.appId)
        onClose();
      }
    } catch (e: any) {
      dispatch(enqueueSnackbar({type: 'error', message: e?.message || `Something went wrong!`}))
    } finally {
      setLoading(false)
    }
  }

  return (
    <Dialog
      keepMounted
      open={open}
      maxWidth={'sm'}
      scroll={'paper'}
      sx={{
        '.css-1t1j96h-MuiPaper-root-MuiDialog-paper': {
          minWidth: 400,
        }
      }}
    >
      <DialogTitle sx={{textAlign: 'center'}}>Update {item.env} configuration</DialogTitle>
      <DialogContent dividers>
        <UpdateConfigEl {...{
          title: '',
          value: body,
          onUpdate: (keys: string[], val: string | string[] | boolean) => updateState(keys, val, body, setBody),
        }} />
      </DialogContent>
      <DialogActions sx={{p: 0}}>
        <ButtonGroup variant="contained" aria-label="Flavor update form actions" sx={{
          flex: 1,
          '> button': {
            flex: 1,
            borderRadius: 0,
          }
        }}>
          <Button variant={'text'} onClick={onClose}>Cancel</Button>
          <LoadingButton {...{
            loading,
            variant: "contained",
            size: "large",
            sx: {
              width: '100%',
            },
            onClick: handleSubmit,
          }}>Submit</LoadingButton>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  )
}