import React, { SyntheticEvent, useState } from "react";
import {
  Box,
  Container,
  FilledInput,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Paper,
  Typography
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { loginFormControlStyle, loginPaperStyle, loginTitleStyle, loginWrapperStyle, } from "../styles/login";
import { useAppSelector } from "../dataStore/hooks";
import { RootState } from "../dataStore/store";
import { AuthAPI } from '../dataStore/apis/authAPI'
import { useNavigate } from "react-router-dom";

export const LoginScreen = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const loading: boolean = useAppSelector((state: RootState) => state.auth.loading);
  const navigate = useNavigate();

  return (
    <Box sx={loginWrapperStyle}>
      <Container maxWidth="xs" className={`login-container`}>
        <Typography variant="h2" gutterBottom sx={loginTitleStyle}>
          Admin Login
        </Typography>
        <Paper elevation={6} sx={loginPaperStyle}>
          <form onSubmit={async (e: SyntheticEvent) => {
            const login: boolean = await AuthAPI.getLoggedIn(e);
            if (login) {
              navigate('/');
            }
          }}>
            <FormControl sx={loginFormControlStyle} variant="filled">
              <InputLabel htmlFor="login-email">Email Address</InputLabel>
              <FilledInput
                id="login-email"
                type={'email'}
                name={`email`}
                required
              />
            </FormControl>
            <FormControl sx={loginFormControlStyle} variant="filled">
              <InputLabel htmlFor="login-password">Password</InputLabel>
              <FilledInput
                id="login-password"
                type={showPassword ? 'text' : 'password'}
                name={`password`}
                required
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff/> : <Visibility/>}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <LoadingButton
              loading={loading}
              loadingPosition="center"
              variant="contained"
              size="large" type={`submit`}
              fullWidth={true}
            >
              LOGIN
            </LoadingButton>
          </form>
        </Paper>
      </Container>
    </Box>
  )
}
