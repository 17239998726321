import { combineReducers, configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
import logger from 'redux-logger'
import auth from "./features/authSlice";
import notifiers from './features/notifierSlice';
import configs from './features/configSlice';
import app from './features/appSlice';

export const store = configureStore({
	reducer: combineReducers({
		auth,
		notifiers,
		configs,
		app
	}),
	middleware: process.env.NODE_ENV !== 'production' ? [thunk, logger] : [],
	devTools: process.env.NODE_ENV !== 'production',
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch