import { SxProps } from "@mui/system/styleFunctionSx/styleFunctionSx";
import { Box, Chip, Divider, Grid, Typography } from "@mui/material";
import { isArray } from "lodash";
import React from "react";

interface iProps {
  title: string,
  value: string | string[] | boolean | null,
  sx?: SxProps,
}

export const ShowConfigRow = ({title, value, sx}: iProps) => {
  return (
    isArray(value)
      ? <Box sx={sx}>
        <Typography sx={{fontSize: 13, whiteSpace: 'nowrap'}} color="text.secondary">{title}</Typography>
        {value.map((v: string) => <Chip label={v} key={v} size={"small"} sx={{mr: 1, mb: 1}}/>)}
      </Box>
      : <Grid container spacing={1} alignItems={"center"} justifyContent={"space-between"} sx={sx}>
        <Grid item sx={{flex: 0}}>
          <Typography sx={{fontSize: 13, whiteSpace: 'nowrap'}} color="text.secondary">{title}</Typography>
        </Grid>
        <Grid item sx={{flex: 1}}><Divider sx={{borderStyle: 'dashed'}}/></Grid>
        <Grid item sx={{flex: 0}}>
          <Typography
            sx={{fontSize: 13, whiteSpace: 'nowrap'}}
            align={"right"}
            color="text.primary">{value === null ? 'N/A' : value.toString()}</Typography>
        </Grid>
      </Grid>
  )
}