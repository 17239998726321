import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { RootState } from "../dataStore/store";
import { useAppDispatch, useAppSelector } from "../dataStore/hooks";
import { removeSnackbar } from "../dataStore/features/notifierSlice";
import { NotificationModel } from "../dataStore/models/NotificationModel";

const useNotifier = () => {
  const dispatch = useAppDispatch();
  const notifications = useAppSelector((store: RootState) => store.notifiers.notifications || []);
  const {enqueueSnackbar} = useSnackbar();

  useEffect(() => {
    notifications.forEach((notification: NotificationModel) => {
      enqueueSnackbar(notification.message, {variant: notification.type});
      setTimeout(() => {
        dispatch(removeSnackbar(notification.message))
      }, 3000)
    });
  }, [notifications, enqueueSnackbar, dispatch]);
};

export default useNotifier;
