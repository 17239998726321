import React, { Dispatch, SetStateAction, useState } from 'react';
import { Button, ButtonGroup, Grid, Typography } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import AddIcon from '@mui/icons-material/Add';
import { useParams } from "react-router-dom";
import { ConfigApi } from "../../dataStore/apis/configApi";
import { AddConfigForm } from '../config/addConfig';
import axios from 'axios';
import { Endpoints } from '../../dataStore/Endpoints';
import { store } from '../../dataStore/store';
import { enqueueSnackbar } from '../../dataStore/features/notifierSlice';
import { indigo } from "@mui/material/colors";

interface iProps {
  result: string[],
  selectedAPP: string,
  setSelectedAPP: Dispatch<SetStateAction<string | null>>,
}

export const AppScreenHeader = ({ result, selectedAPP, setSelectedAPP }: iProps) => {
  const { type } = useParams();
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const getReload = async () => {
    if (!!type)
      await ConfigApi.getApps(type);
    if (!!selectedAPP)
      await ConfigApi.getConfigs(type!, selectedAPP);
  }
  const submitNewApp = async (payload: any) => {
    setOpen(false);
    try {
      setLoading(true);
      const res = (await axios.post(Endpoints.addApps(type!), payload)).data;
      if (res.error == null) {
        await getReload()
      } else {
        store.dispatch(enqueueSnackbar({ type: "error", message: `Something went wrong!` }))
      }
    } catch (e: any) {
      store.dispatch(enqueueSnackbar({ type: "error", message: e?.message || `Something went wrong!` }));
    } finally {
      setLoading(false);
    }
  }

  return (
    <Grid
      container
      spacing={1}
      sx={{ mb: 2, flexDirection: 'column', }}
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      <Grid item>
        <Typography variant={'h5'} component={'h2'}>Choose an APP</Typography>
      </Grid>
      <Grid item>
        <ButtonGroup variant="contained" aria-label="app list" size={"large"}>
          {result.map((item: string) => (
            <Button
              key={`app-btn-${item}`}
              onClick={() => selectedAPP === item ? {} : setSelectedAPP(item)}
              sx={{
                backgroundColor: selectedAPP === item ? indigo[500] : indigo[50],
                color: selectedAPP === item ? 'white' : indigo[900],
                '&:hover,&:focus': {
                  color: 'white',
                }
              }}
            >
              {item.split('_').join(' ').toUpperCase()}
            </Button>
          ))}
        </ButtonGroup>
      </Grid>
      <Grid item>
        <ButtonGroup variant="text" aria-label="app list" size={"large"}>
          <LoadingButton
            loading={loading}
            onClick={() => setOpen(true)}
            startIcon={<AddIcon />}
            loadingPosition="start"
          >
            Add new {type} APP
          </LoadingButton>
        </ButtonGroup>
      </Grid>
      <AddConfigForm {...{
        open, onClose: () => setOpen(false), onSubmit: async (payload) => submitNewApp(payload)
      }} />
    </Grid>

  )
}