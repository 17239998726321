import { Button, ButtonGroup, Card, CardActions, CardContent, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { IAdminFlavor, IMobileFlavor } from "hrms_types";
import { ShowConfigEl } from "./ShowConfigEl";
import { DeleteDialog } from "./DeleteDialog";
import { pick } from "lodash";
import { FlavorUpdateForm } from "./FlavorUpdateForm";

interface iProps {
  item: IAdminFlavor | IMobileFlavor,
  onDelete: (id: string) => void,
}

export const ConfigCard = ({item, onDelete}: iProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [dOpen, setDOpen] = useState<boolean>(false);
  const {env, id} = item;
  const _obj = pick(item, ['essential', 'access'])

  return (
    <>
      <Grid item xs={12} sm={6} lg={4}>
        <Card>
          <CardContent>
            <Typography variant="h6" component="h2" align={"center"} gutterBottom>
              {env.toUpperCase()}
            </Typography>
            <ShowConfigEl {...{title: '', value: _obj,}}/>
          </CardContent>

          <CardActions sx={{p: 0}}>
            <ButtonGroup
              variant="contained"
              aria-label="Config card actions"
              sx={{
                flex: 1,
                '> button': {
                  flex: 1,
                  borderRadius: 0,
                }
              }}
              color={'info'}
            >
              <Button onClick={() => setDOpen(true)} color={'error'}>Delete</Button>
              <Button onClick={() => setOpen(true)}>Update</Button>
            </ButtonGroup>
          </CardActions>
        </Card>
      </Grid>
      <FlavorUpdateForm {...{item, open, onClose: () => setOpen(false)}} />
      <DeleteDialog open={dOpen} onClose={() => setDOpen(false)} onDelete={() => onDelete(id)}/>
    </>
  )
}