import {SxProps} from "@mui/system/styleFunctionSx/styleFunctionSx";
import {grey} from "@mui/material/colors";

export const loginWrapperStyle: SxProps = {
  width: `100vw`,
  minHeight: `calc(100vh - 74px - 56px)`,
  overflowY: "hidden",
  backgroundImage: "url(/images/login-bg.jpg)",
  backgroundSize: "cover",
  display: "flex",
  alignItems: 'center',
  position: "relative",
  my: -5,
  "&::before": {
    content: `""`,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 0,
    backgroundColor: grey[900],
    opacity: 0.7,
  },
  "& > .login-container": {
    position: "relative",
    zIndex: 1,
  }
}

export const loginTitleStyle: SxProps = {
  fontFamily: `cursive`,
  color: grey[50],
  textAlign: "center",
}

export const loginPaperStyle: SxProps = {
  padding: 3,
}

export const loginFormControlStyle: SxProps = {
  width: '100%',
  mb: 2,
}