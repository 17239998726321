import React, {MouseEvent, useState} from 'react';
import {Avatar, Box, IconButton, Menu, MenuItem, Tooltip, Typography} from "@mui/material";
import {getLogout} from "../../dataStore/features/authSlice";
import {useAppDispatch, useAppSelector} from "../../dataStore/hooks";
import {AdminDataModel, IAdminDataModel} from "../../dataStore/models/LoginModel";
import {useNavigate} from "react-router-dom";
import {orderBy, uniqBy} from "lodash";

interface Setting {
  key: number,
  name: string;
  action: string | Function;
}

let settings: Setting[] = [
  {
    key: 10,
    name: `Logout`,
    action: getLogout,
  },
];

const ProfileMenu = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const {name}: AdminDataModel = new AdminDataModel(useAppSelector(state => state.auth.admin) as IAdminDataModel);

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const orderedList = uniqBy(orderBy(settings, ['key']), 'key')

  return (
    <Box sx={{flexGrow: 0}}>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
          <Avatar alt={name} src="/"/>
        </IconButton>
      </Tooltip>
      <Menu
        sx={{mt: 6}}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {orderedList.map(({key, name, action}: Setting) => {
          return (
            <MenuItem key={`profile-menu-item-${key}`} onClick={handleCloseUserMenu}>
              <Typography
                textAlign="center"
                onClick={() => typeof action === "function" ? dispatch(action()) : navigate(action)}
              >{name}</Typography>
            </MenuItem>
          )
        })}
      </Menu>
    </Box>
  );
};

export default ProfileMenu;
