import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import decode from 'jwt-decode';
import { isJwtExpired } from "../../utilities/jwt_tools";
import { RootState } from "../store";
import axios from "axios";
import { AdminDataWrapperModel, IAdminDataModel, LoginResponseModel } from "../models/LoginModel";
import { isEmpty } from 'lodash';

const initialState: LoginResponseModel = {
  token: null,
  admin: null,
  loading: false,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoaderON: (state: LoginResponseModel) => {
      state.loading = true;
    },
    setLoaderOFF: (state: LoginResponseModel) => {
      state.loading = false;
    },
    getLogged: (state: LoginResponseModel, action: PayloadAction<string>) => {
      axios.defaults.headers.common[`Authorization`] = `Bearer ${action.payload}`;
      state.token = action.payload
      state.admin = (decode(action.payload) as AdminDataWrapperModel).data
    },
    getLogout: (state: LoginResponseModel) => {
      axios.defaults.headers.common[`Authorization`] = ``;
      localStorage.removeItem('token')
      state.token = null;
      state.admin = {} as IAdminDataModel;
    },
  },
});

export const {
  getLogged,
  getLogout,
  setLoaderOFF,
  setLoaderON,
} = authSlice.actions

export const isLoggedIn = (state: RootState) => !state.auth.loading && !!state.auth.token && !!state.auth.admin && !isEmpty(state.auth.admin) && !isJwtExpired(state.auth.token)


export default authSlice.reducer