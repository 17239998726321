import axios from "axios";
import { Endpoints } from "../Endpoints";
import { setAppData, setAppLoaderON, setAppLoaderOFF } from "../features/appSlice";
import { setData, setLoaderOFF, setLoaderON } from "../features/configSlice";
import { enqueueSnackbar } from "../features/notifierSlice";
import { AppResponseModel } from "../models/AppModel";
import { ConfigResponseModel } from "../models/ConfigModel";
import { store } from "../store";

export class ConfigApi {
  static getApps = async (type: string) => {
    try {
      store.dispatch(setAppLoaderON())
      const res: AppResponseModel = (await axios.get(Endpoints.getApps(type))).data;
      store.dispatch(setAppData(res))
    } catch (e: any) {
      store.dispatch(enqueueSnackbar({type: "error", message: e?.message || `Something went wrong!`}))
    } finally {
      store.dispatch(setAppLoaderOFF())
    }
  }

  static getConfigs = async (flavorType: string, appType: string) => {
    store.dispatch(setLoaderON());
    try {
      const data: ConfigResponseModel = (await axios({
        method: 'GET',
        url: Endpoints.getConfigs(flavorType, appType),
      })).data;

      store.dispatch(setData(data))
      if (data.error) {
        store.dispatch(enqueueSnackbar({type: "error", message: data.error.message}))
      }
    } catch (e: any) {
      store.dispatch(enqueueSnackbar({type: "error", message: e?.message || `Something went wrong!`}))
    } finally {
      store.dispatch(setLoaderOFF());
    }
  }
}