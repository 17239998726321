import LoadingButton from "@mui/lab/LoadingButton";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, TextField, } from "@mui/material"
import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react'
import { UpdateConfigEl } from "./UpdateConfigEl";

interface iProps {
  open: boolean,
  onClose: () => void,
  onSubmit: (payload: any) => void;
}

export const updateState = (
  keys: string[],
  val: string | string[] | boolean,
  primary: { [key: string]: any },
  setter: Dispatch<SetStateAction<{ [p: string]: any }>>,
) => {
  let obj: { [key: string]: any } = {...primary},
    tmpObj: { [key: string]: any } = {},
    prevObj: { [key: string]: any } = {}
  for (let x = keys.length - 1; x >= 0; x--) {
    if (x === 0) {
      obj[keys[0]] = tmpObj
    } else {
      let toEval = 'primary.' + keys.slice(0, x).join('.');
      prevObj = {
        ...tmpObj
      }
      tmpObj = {...eval(toEval)};
      if (x === keys.length - 1) {
        tmpObj[keys[x]] = typeof tmpObj[keys[x]] === "number" ? Number(val) : val;
      } else {
        tmpObj[keys[x]] = prevObj
      }
    }
  }
  setter(obj);
}

export const AddConfigForm = ({open, onClose, onSubmit}: iProps) => {
  const [env, setEnv] = useState<string>(``);
  const [appID, setAppID] = useState<string>(``);
  const [body, setBody] = useState<{ [key: string]: any }>({'essential': {}, 'access': {}});

  return (
    <Dialog keepMounted open={open} maxWidth={'sm'}>
      <Grid container justifyContent={"space-between"} alignItems={"center"}>
        <Grid item>
          <DialogTitle>Add New configuration</DialogTitle>
        </Grid>
        <Grid item>
          <Button autoFocus onClick={onClose} sx={{mr: 2}}>Close</Button>
        </Grid>
      </Grid>

      <DialogContent>
        <FormControl variant="standard" sx={{minWidth: '100%', mb: 1}}>
          <TextField
            id={`config-baseUrl`}
            label={`Environment`}
            variant="standard"
            onChange={(e: ChangeEvent<HTMLInputElement>) => setEnv(e.target.value)}
            required
          />
        </FormControl>
        <FormControl variant="standard" sx={{minWidth: '100%', mb: 1}}>
          <TextField
            id={`config-baseUrl`}
            label={`Application ID`}
            variant="standard"
            onChange={(e: ChangeEvent<HTMLInputElement>) => setAppID(e.target.value)}
            required
          />
        </FormControl>
      </DialogContent>

      <DialogContent dividers>
        <UpdateConfigEl {...{
          title: '',
          value: body,
          onUpdate: (keys: string[], val: string | string[] | boolean) => updateState(keys, val, body, setBody),
        }} />
      </DialogContent>
      <DialogActions sx={{justifyContent: "flex-start"}}>
        <LoadingButton {...{
          variant: "contained",
          size: "large",
          sx: {
            width: '100%',
          },
          onClick: () => onSubmit({env: env, appId: appID, essential: body.essential, access: body.access}),
        }}>Submit</LoadingButton>
      </DialogActions>
    </Dialog>
  )
}