import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide
} from "@mui/material";
import React, {
  forwardRef,
  ForwardRefExoticComponent,
  MouseEventHandler,
  ReactElement,
  Ref,
  RefAttributes
} from "react";
import { TransitionProps } from "@mui/material/transitions";
import { red } from "@mui/material/colors";

interface iProps {
  open: boolean,
  onClose: MouseEventHandler<HTMLButtonElement>,
  onDelete: MouseEventHandler<HTMLButtonElement>,
}

const Transition: ForwardRefExoticComponent<TransitionProps & { children: ReactElement<any, any> } & RefAttributes<unknown>> = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props}/>;
});

export const DeleteDialog = ({open, onClose, onDelete}: iProps) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      maxWidth={'xs'}
      aria-describedby="flavor-delete-action-confirmation-dialog"
      sx={{
        textAlign: 'center',
      }}
    >
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Once deleted this record will be gone permanently. <br/>
          Are you sure to <strong style={{color: red[900]}}>delete</strong> this?
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{p: 0}}>
        <ButtonGroup
          variant="contained"
          aria-label="Flavor delete actions"
          sx={{
            flex: 1,
            '> button': {
              flex: 1,
              borderRadius: 0,
            }
          }}
          color={'inherit'}
        >
          <Button variant={'text'} onClick={onClose}>Cancel</Button>
          <Button onClick={onDelete} color={'error'}>Delete</Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  )
}