import { Card, CardContent, Container, Grid, Icon, Typography, } from '@mui/material';
import React from 'react';
import AuthRoute from "../components/global/authRoute";
import { Link } from "react-router-dom";
import { theme } from "../utilities/theme";

interface MenuListITem {
  title: string;
  icon: string;
  path: string;
}

const list: MenuListITem[] = [
  { title: `Mobile Configurations`, icon: `phone_iphone`, path: `/mobile-apps` },
  { title: `Dashboard Configurations`, icon: `dashboard`, path: `/admin-apps` },
]

const HomeScreenInner = () => {
  return (
    <Container maxWidth={"sm"}>
      <Grid container spacing={3} sx={{ textAlign: "center" }} justifyContent={`center`}>
        {list.map(({ title, icon, path }: MenuListITem, index: number) => (
          <Grid item xs={6} sm={6} key={`admin-screens-${index}`}>
            <Link to={path} style={{
              color: theme.palette.primary.dark,
              textDecoration: "none",
            }}>
              <Card sx={{
                py: 3,
              }}>
                <CardContent>
                  <Icon sx={{fontSize: 100}} color="primary">{icon}</Icon>
                  <Typography variant="body1">
                    {title}
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export const HomeScreen = (props: any) => <AuthRoute><HomeScreenInner {...props} /></AuthRoute>
