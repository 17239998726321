import { Breadcrumbs, Icon, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { blue } from "@mui/material/colors";

interface Item {
  title: string,
  icon: string,
  path?: string
}

interface iProps {
  items: Item[]
}

export const AppBreadcrumb = ({items}: iProps) => {
  return (
    <Breadcrumbs aria-label="breadcrumb" sx={{
      mt: -3,
      mb: 2,
      '& li > a': {
        color: blue[900],
        textDecoration: `none`,
        "&:hover": {
          textDecoration: 'underline',
        }
      }
    }}>
      <Link
        style={{display: 'flex', alignItems: 'center'}}
        color="inherit"
        to="/"
      >
        <Icon sx={{mr: 0.5}} fontSize="inherit">home</Icon>
        Home
      </Link>
      {items.map(({title, icon, path}: Item, index: number) => !!path ? (
        <Link
          style={{display: 'flex', alignItems: 'center'}}
          color="inherit"
          to={path}
          key={`breadcrumb-item-${index + 1}-with-path`}
        >
          <Icon sx={{mr: 0.5}} fontSize="inherit">{icon}</Icon>
          {title}
        </Link>
      ) : (
        <Typography
          sx={{display: 'flex', alignItems: 'center'}}
          color="text.primary"
          key={`breadcrumb-item-${index + 1}-without-path`}
        >
          <Icon sx={{mr: 0.5}} fontSize="inherit">{icon}</Icon>
          {title}
        </Typography>
      ))}
    </Breadcrumbs>
  )
}