import React from 'react';
import {Box} from "@mui/material";
import "../styles/error.css";

interface IAppErrorProps {
  code?: number;
  message?: string;
}

const AppErrorScreen = ({code, message}: IAppErrorProps) => {
  return (
    <Box component={`section`} sx={{my: `auto`}}>
      <Box component={`div`} className={`number`}>{code || 404}</Box>
      <div className="text">
        <span>Ooops...</span>
        <br/>
        {message || 'page not found'}
      </div>
    </Box>
  );
};

export default AppErrorScreen;
