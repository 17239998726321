import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ConfigModel, ConfigResponseModel } from "../models/ConfigModel";

const initialState: ConfigModel = {
  loading: false,
  data: {
    error: null,
    result: [],
  },
}

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setLoaderON: (state: ConfigModel) => {
      state.loading = true;
    },
    setLoaderOFF: (state: ConfigModel) => {
      state.loading = false;
    },
    setData: (state: ConfigModel, action: PayloadAction<ConfigResponseModel>) => {
      state.data = action.payload;
    },
  },
});

export const {
  setData,
  setLoaderOFF,
  setLoaderON,
} = adminSlice.actions

export default adminSlice.reducer