import React from 'react';
import {Container, Link, Typography,} from "@mui/material";
import { AppBreadcrumb } from "../components/global/breadcrumb";

const PrivacyPolicyScreen = () => {
  return (
    <Container>
      <AppBreadcrumb items={[{title: 'Privacy Policy', icon: 'policy'}]} />
      <Typography variant={"h3"} component={`h1`} sx={{mb: 3}}>Privacy Policy</Typography>

      <Typography sx={{mb: 2}}>
        Flyhub Play is an internal mobile app of <Link href={`https://flyhub.com`} target={`blank`}>Flyhub Travel Bangladesh
        Ltd.</Link> Flyhub is a brand of HIS Group PLC.
      </Typography>

      <Typography sx={{mb: 2}}>
        Flyhub Play mobile app helps to track time with gps location for the employees. To use Flyhub Play,
        Google&apos;s Gsuite login is required as it is an internal app.
      </Typography>

      <Typography sx={{mb: 2}}>
        This app collects data for app functionality which includes Location, Email, Name, Photo Url of the employee
        logged in to the app using Flyhub email address provided by Flyhub.
      </Typography>

      <Typography sx={{mb: 2}}>
        Data can be deleted from admin panel that is handled by Flyhub Authority. As this app is an internal app for
        employees to track time, Flyhub collects their data legally and Flyhub has all rights.
      </Typography>

    </Container>
  );
};

export default PrivacyPolicyScreen;
