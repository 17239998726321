import { Box, CircularProgress } from "@mui/material";
import React from "react";

export const FullPageLoader = () => (
	<Box sx={{
		display: `flex`,
		width: `100%`,
		height: `calc(100vh - 74px - 56px)`,
		alignItems: "center",
		justifyContent: "center",
	}}>
		<CircularProgress color={"info"} sx={{mr: 2}}/>
		<span>Loading...</span>
	</Box>
)

export const FullPageLoader2 = () => (
	<Box sx={{
		display: `flex`,
		alignItems: `center`,
		justifyContent: `center`,
		width: `100vw`,
		height: `calc(100vh - 210px)`,
	}}>
		<CircularProgress/>
	</Box>
)